export default class Sidebar {
	constructor(holder) {
		this.$holder = document.querySelector(holder);

		// Binding THIS
		this.setup = this.setup.bind(this);
		this.reset = this.reset.bind(this);
		this.toggleNav = this.toggleNav.bind(this);
		this.eventListeners = this.eventListeners.bind(this);

		// If holder exists
		if (this.$holder) {
			this.init();
		}
	}

	setup() {
		this.$body = document.getElementsByTagName('body')[0];
		this.$toggleNav = this.$holder.querySelector('.m-sidebar__toggle');
		this.$navLabel = this.$holder.querySelectorAll(
			'.m-sidebar__nav__label'
		);
	}

	eventListeners() {
		this.$toggleNav.addEventListener('click', this.toggleNav);
	}

	toggleNav() {
		this.$body.classList.toggle('is--m-sidebar--open');
		this.$toggleNav.classList.toggle('is--active');

		if (this.$body.classList.contains('is--m-sidebar--open')) {
			setTimeout(() => {
				for (let i = 0; i < this.$navLabel.length; i++) {
					this.$navLabel[i].style.display = 'inline';
				}
			}, 200);
		} else {
			for (let i = 0; i < this.$navLabel.length; i++) {
				this.$navLabel[i].style.display = 'none';
			}
		}
	}

	reset() {
		if (this.$toggleNav) {
			this.$body.classList.remove('is--m-sidebar--open');
			this.$toggleNav.classList.remove('is--active');
			for (let i = 0; i < this.$navLabel.length; i++) {
				this.$navLabel[i].style.display = 'none';
			}
		}
	}

	// Initialize
	init() {
		this.setup();
		this.eventListeners();
		this.reset();
	}
}
