/*eslint-disable */
import axios from 'axios';
import ChapterEditorTemplate from '../../../templates/chapterEditorTemplate';
import ChapterParagraphEditorTemplate from '../../../templates/chapterParagraphEditorTemplate';
import ParagraphEditorTemplate from '../../../templates/paragraphsEditorTemplate';
// import tinyMCE from 'tinymce';

export default class ContentManager {
	constructor(holder) {
		this.$holder = document.querySelector(holder);

		// Binding THIS
		this.setup = this.setup.bind(this);
		this.addNewChapter = this.addNewChapter.bind(this);
		this.addNewParagraph = this.addNewParagraph.bind(this);
		this.eventListeners = this.eventListeners.bind(this);
		this.retrievePubishedEntities = this.retrievePubishedEntities.bind(this);
		this.updateButtonListeners = this.updateButtonListeners.bind(this);
		this.updateTable = this.updateTable.bind(this);
		this.setupTableListener = this.setupTableListener.bind(this);
		this.addParagraphLib = this.addParagraphLib.bind(this);
		this.addChapterLib = this.addChapterLib.bind(this);
		this.searchChapters = this.searchChapters.bind(this);
		this.searchParagraphs = this.searchParagraphs.bind(this);

		this.paragraphPageIndex = 1;
		this.chapterPageIndex = 1;


		// If holder exists
		if (this.$holder) {
			this.retrievePubishedEntities('paragraphs',(paragraphs) => {

				this.$paragraphsList = paragraphs;

				this.retrievePubishedEntities('chapters',(chapters) => {

					this.$chaptersList = chapters;

					this.init();
				});
			});
		}
	}

	setup(paragraphs, chapters) {
		this.$hostname = `${window.location.protocol}//${window.location.host}`;

		this.$paragraphList = paragraphs;
		this.$chapterList = chapters;
		this.$paragraphsData = {};
		this.$chaptersData = {};
		this.$clickedElm = null;

		this.$addNewChapter = this.$holder.querySelector('[value="add-new-chapter"]');
		this.$addNewParagraph = this.$holder.querySelectorAll('[value="add-new-paragraph"]');
		this.$addExistingChapter = this.$holder.querySelector('[value="add-existing-chapter"]');
		this.$addExistingParagraph = this.$holder.querySelectorAll('[value="add-existing-paragraph"]');
		this.$contentList = this.$holder.querySelector('.m-content-manager__list');
		this.$paragraphModal = this.$holder.querySelector('#m-modal-paragraphs');
		this.$chapterModal = this.$holder.querySelector('#m-modal-chapters');

		if (this.$chapterModal) {
			this.$chapterModalSave = this.$chapterModal.querySelector('.o-btn--save');
			this.$chaptersModalList = this.$chapterModal.querySelector('.m-chapters-list');
		}

		if (this.$paragraphModal) {
			this.$paragraphModalSave = this.$paragraphModal.querySelector('.o-btn--save');
			this.$paragraphModalList = this.$paragraphModal.querySelector('.m-paragraphs-list');
		}

		this.$cards = this.$holder.querySelectorAll('.o-card');
		this.$toggleBtn = this.$holder.querySelectorAll('.o-card__toggle');
		this.$textAreas = this.$holder.querySelectorAll('.o-editor__textarea');

		this.$formSearchParagraphs = this.$holder.querySelectorAll('.m-paragraphs-modal .m-search__search form');
		this.$inputSearchParagraphs = this.$holder.querySelectorAll('.m-paragraphs-modal .o-inputfield.o-inputfield__search');
		this.$buttonSearchParagraphs = this.$holder.querySelectorAll('.m-paragraphs-modal #submit');
		$(this.$buttonSearchParagraphs).find('.fas').hide();

		this.$formSearchChapters = this.$holder.querySelectorAll('.m-chapters-modal .m-search__search form');
		this.$inputSearchChapters = this.$holder.querySelectorAll('.m-chapters-modal .o-inputfield__search');
		this.$buttonSearchChapters = this.$holder.querySelectorAll('.m-chapters-modal button');
		$(this.$buttonSearchChapters).find('.fas').hide();

		// If content list exists
		if (this.$contentList) {
			this.$deleteBtn = this.$contentList.querySelectorAll('.js--delete-confirm');
			this.$moveUpBtn = this.$contentList.querySelectorAll('.js--move-up');
			this.$moveDownBtn = this.$contentList.querySelectorAll('.js--move-down');
		}

		if (this.$textAreas) {
			for (let textArea in this.$textAreas) {
				if (typeof this.$textAreas[textArea] === 'object') {
					this.initEditor(
						this.$textAreas[textArea].getAttribute('id')
					);
				}
			}
		}
	}

	eventListeners() {
		let self = this;

		this.prepareToggleButton(this.$toggleBtn);
		this.prepareSearchInputChapters(this.$inputSearchChapters);
		this.prepareSearchInputParagraphs(this.$inputSearchParagraphs);

		if (this.$addNewChapter) {
			this.prepareAddChapterButton(this.$addNewChapter);
		}

		if (this.$addNewParagraph) {
			this.prepareAddParagraphButton(this.$addNewParagraph);
		}

		if (this.$contentList) {
			this.prepareMoveButton(this.$moveUpBtn);
			this.prepareMoveButton(this.$moveDownBtn);
			this.prepareDeleteButton(this.$deleteBtn);
		}

		if (!this.$holder.classList.contains('m-content-manager--paragraph')) {
			if (this.$addExistingChapter) {
				this.$chapterModalSave.addEventListener(
					'click',
					this.addChapterLib
				);
				this.prepareAddExistingChapters(this.$addExistingChapter);
			}

			if (this.$addExistingParagraph) {
				if (this.$paragraphModalSave) {
					this.$paragraphModalSave.addEventListener(
						'click',
						this.addParagraphLib
					);
				}
				this.prepareAddExistingParagraphs(this.$addExistingParagraph);
			}
		}

		$(this.$formSearchParagraphs).submit(function( event ) {
			event.preventDefault();
			self.searchParagraphsByQuery($(self.$inputSearchParagraphs).val());
		});

		$(this.$formSearchChapters).submit(function( event ) {
			event.preventDefault();
			self.searchChaptersByQuery($(self.$inputSearchChapters).val());
		});
	}

	eventHandler() {}

	disableSubmit() {
		document.onkeypress = e => {
			var key = e.charCode || e.keyCode || 0;
			if (key === 13) {
				e.preventDefault();
			}
		};
	}

	prepareToggleButton(ell) {
		for (let i = 0; i < ell.length; i++) {
			ell[i].removeEventListener('click', this.eventHandler);
			ell[i].addEventListener('click', this.toggleCard);
		}
	}

	prepareMoveButton(ell) {
		for (let i = 0; i < ell.length; i++) {
			ell[i].removeEventListener('click', this.eventHandler);
			ell[i].addEventListener('click', this.moveElement);
		}
	}

	prepareDeleteButton(ell) {
		for (let i = 0; i < ell.length; i++) {
			ell[i].removeEventListener('click', this.eventHandler);
			ell[i].addEventListener('click', this.deleteElement);
		}
	}

	prepareAddChapterButton(ell) {
		ell.addEventListener('click', this.addNewChapter);
	}

	prepareAddParagraphButton(ell) {
		if (ell instanceof NodeList) {
			// if there are multple add paragraphs buttons on guide view
			for (let i = 0; i < ell.length; i++) {
				ell[i].removeEventListener('click', this.eventHandler);
				ell[i].addEventListener('click', this.addNewParagraph);
			}
		} else {
			// Event listeners for one button on chapter view
			ell.removeEventListener('click', this.eventHandler);
			ell.addEventListener('click', this.addNewParagraph);
		}
	}

	prepareAddExistingParagraphs(ell) {
		if (ell instanceof NodeList) {
			// if there are multple add existing paragraphs buttons on guide view
			for (let i = 0; i < ell.length; i++) {
				ell[i].removeEventListener('click', this.eventHandler);

				this.disableSubmit();

				ell[i].addEventListener('click', event => {
					this.updateTable(
						event,
						this.$paragraphModalList,
						this.$paragraphsList
					);
				});
			}
		} else {
			// Event listeners for one button on chapter view
			ell.addEventListener('click', event => {
				this.disableSubmit();
				this.updateTable(
					event,
					this.$paragraphModalList,
					this.$paragraphsList
				);
			});
		}
	}

	prepareAddExistingChapters(ell) {
		if(this.$chaptersList !== undefined){
			ell.addEventListener('click', event => {
				this.disableSubmit();

				this.updateTable(
					event,
					this.$chaptersModalList,
					this.$chaptersList
				);

			});
		}
	}

	prepareSearchInputChapters(ell) {
		/*
		for (let [key, inputField] of Object.entries(ell)) {
			inputField.addEventListener('input', event => {
				console.log('event input');
				let query = event.target.value;
				let self = this;
				self.chapterQuery = event.target.value;
				self.chapterPageIndex = 0;
				this.searchChapters(query, function(response) {
					self.updateTable(event, self.$chaptersModalList, response);
				});
			});
		}
		*/
	}

	prepareSearchInputParagraphs(ell) {
		/*
		for (let [key, inputField] of Object.entries(ell)) {
			inputField.addEventListener('input', event => {
				let query = event.target.value;
				let self = this;
				self.paragraphQuery = event.target.value;
				self.paragraphPageIndex = 0;
				this.searchParagraphs(query, function(response) {
					self.updateTable(event, self.$paragraphModalList, response);
				});
			});
		}
		*/
	}

	updateButtonListeners() {
		this.$paragraphsData = {};
		this.$chaptersData = {};
		this.$addNewChapter = this.$holder.querySelector(
			'[value="add-new-chapter"]'
		);
		this.$addNewParagraph = this.$holder.querySelectorAll(
			'[value="add-new-paragraph"]'
		);
		this.$addExistingChapter = this.$holder.querySelector(
			'[value="add-existing-chapter"]'
		);
		this.$addExistingParagraph = this.$holder.querySelectorAll(
			'[value="add-existing-paragraph"]'
		);
		this.$toggleBtn = this.$holder.querySelectorAll('.o-card__toggle');
		this.$deleteBtn = this.$contentList.querySelectorAll(
			'.js--delete-confirm'
		);
		this.$moveUpBtn = this.$contentList.querySelectorAll('.js--move-up');
		this.$moveDownBtn = this.$contentList.querySelectorAll(
			'.js--move-down'
		);

		this.prepareToggleButton(this.$toggleBtn);
		this.prepareMoveButton(this.$moveUpBtn);
		this.prepareMoveButton(this.$moveDownBtn);
		this.prepareDeleteButton(this.$deleteBtn);

		// If add new chapter button exists
		if (this.$addNewChapter) {
			this.$chapterModalSave.addEventListener(
				'click',
				this.addChapterLib
			);
			this.prepareAddChapterButton(this.$addNewChapter);
		}

		// If add new paragraph button exists
		if (this.$addNewParagraph) {
			this.prepareAddParagraphButton(this.$addNewParagraph);
		}

		if (this.$addExistingParagraph) {
			this.$paragraphModalSave = this.$paragraphModal.querySelector(
				'.o-btn--save'
			);
			this.$paragraphModalSave.addEventListener(
				'click',
				this.addParagraphLib
			);
			this.prepareAddExistingParagraphs(this.$addExistingParagraph);
		}
	}

	toggleCard(e) {
		e.target.closest('.o-card').classList.toggle('is--collapsed');
	}

	moveElement(e) {
		let directionUp = e.target.classList.contains('js--move-up');
		let directionDown = e.target.classList.contains('js--move-down');
		let element;
		let type;

		if (e.target.closest('.o-card--paragraph')) {
			type = 'o-card--paragraph';
		} else {
			type = 'o-card--chapter';
		}

		element = e.target.closest('.' + type);

		if (directionUp) {
			let prevElement = element.previousElementSibling;

			if (prevElement && prevElement.classList.contains(type)) {
				// Going up
				prevElement.parentNode.insertBefore(element, prevElement);
			} else {
				// Already first element
			}
		} else if (directionDown) {
			let nextElement = element.nextElementSibling;

			if (nextElement && nextElement.classList.contains(type)) {
				// Going down
				nextElement.parentNode.insertBefore(nextElement, element);
			} else {
				// Already last element
			}
		}

		// Reinit TinyMCE
		window.tinyMCE.remove('textarea');
		window.tinyMCE.init({
			selector: '.o-editor__textarea',
			mode: 'none',
			hidden_input: false,
			branding: false,
			menubar: false,
			height: 250,
			images_upload_url: `${this.$hostname}/admin/uploads/store`,
			images_upload_base_path: `/`,
			relative_urls : false,
			remove_script_host : false,
			convert_urls : false,
			plugins: 'image link lists code',
			toolbar: [
				'formatselect | bold italic link | image | numlist bullist | code'
			]
		});
	}

	deleteElement(e) {
		let element = e.target.closest('.o-card');

		element.style.display = 'none';
		element.parentNode.removeChild(element);
	}

	addNewChapter(chapter, data) {
		let indexChapter = this.$contentList.querySelectorAll(
			'.o-card--chapter'
		).length;

		this.$contentList.insertAdjacentHTML(
			'beforeend',
			ChapterEditorTemplate(
				indexChapter,
				data !== undefined ? data : null,
				data !== undefined ? data.shared : false
			)
		);

		if ((data != undefined && !data.shared) || data == undefined) {
			this.initEditor(`chapter-editor-${indexChapter}`);
		}

		this.updateButtonListeners();
	}

	addNewParagraph(e, data) {
		// return;
		let paragraphIndex;

		if (this.$holder.querySelector('.m-chapters__list')) {
			paragraphIndex = this.$contentList.querySelectorAll(
				'.o-card--paragraph'
			).length;

			if (e.target) {
				this.$clickedElm = e.target;
				// If adding paragraph from library to chapter on guide view
				let currentChapter = this.$clickedElm.closest(
					'.o-card--chapter'
				);

				let paragraphList = currentChapter.querySelector(
					'.m-paragraphs__list'
				);

				let currentChapterIndex = currentChapter.getAttribute(
					'data-index'
				);

				paragraphList.insertAdjacentHTML(
					'beforeend',
					ChapterParagraphEditorTemplate(
						paragraphIndex,
						data !== undefined ? data : null,
						data !== undefined ? data.shared : false,
						currentChapterIndex
					)
				);

				console.log(currentChapterIndex, paragraphIndex);
				this.initEditor(`chapter-${currentChapterIndex}-paragraph-editor-${paragraphIndex}`);

				// Reset var
				this.$clickedElm = null;
			}

			else {
				// Adding paragraph to chapter on guide view
				let currentChapter;
				if (e.target) {
					currentChapter = e.target.closest('.o-card--chapter');
				} else {
					// Adding paragraph from libary on guides view
					currentChapter = e.closest('.o-card--chapter');
				}
				let currentChapterIndex = currentChapter.getAttribute(
					'data-index'
				);
				let paragraphList = currentChapter.querySelector(
					'.m-paragraphs__list'
				);

				paragraphList.insertAdjacentHTML(
					'beforeend',
					ChapterParagraphEditorTemplate(
						paragraphIndex,
						data !== undefined ? data : null,
						data !== undefined ? data.shared : false,
						currentChapterIndex
					)
				);
			}

			if (e.target) {
				this.$clickedElm = e.target;
				// If adding paragraph from library to chapter on guide view
				let currentChapter = this.$clickedElm.closest('.o-card--chapter');
				this.initEditor(`chapter-${currentChapter}-paragraph-editor-${paragraphIndex}`);
				this.$clickedElm = null;

			}
			else if ((data !== undefined && !data.shared) || data === undefined) {
				this.initEditor(`chapter-paragraph-editor-${paragraphIndex}`);
			}
		}

		else if (this.$holder.querySelector('.m-paragraphs__list')) {
			// Adding paragraph to chapter in chapter view
			let paragraphs = this.$contentList.querySelectorAll(
				'.o-card--paragraph'
			);
			paragraphIndex = paragraphs.length;
			this.$contentList.insertAdjacentHTML(
				'beforeend',
				ParagraphEditorTemplate(
					paragraphIndex,
					data !== undefined ? data : null,
					data !== undefined ? data.shared : false
				)
			);

			if ((data != undefined && !data.shared) || data == undefined) {
				this.initEditor(`paragraph-editor-${paragraphIndex}`);
			}
		}

		this.updateButtonListeners();
	}

	loadMoreChapters() {
		let self = this;
		let _tableRow = '<div class="o-table__loadmore">Scroll omlaag om meer te laden...</div>';

		this.$chapterModal
			.querySelector('.modal-body')
			.insertAdjacentHTML('beforeend', _tableRow);

		this.$chapterModal
			.querySelector('.modal-body')
			.addEventListener('scroll', function() {
				if (this.scrollTop + this.clientHeight >= this.scrollHeight) {

					self.chapterPageIndex++;

					self.searchChaptersByOffset(self.chapterQuery, self.chapterPageIndex , function(
						chapters
					) {
						if (
							typeof chapters !== 'undefined' &&
							chapters.length > 0
						) {
							self.updateTable(
								null,
								self.$chaptersModalList,
								self.$chaptersList
							);
						} else {
							self.$chapterModal.querySelector(
								'.o-table__loadmore'
							).style.display =
								'none';
						}
					});
				}
			});

		// force update of list of chapters
		self.updateTable(
			null,
			self.$chaptersModalList,
			self.$chaptersList
		);
	}

	loadMoreParagraphs() {
		let self = this;
		let _tableRow =
			'<div class="o-table__loadmore">Scroll omlaag om meer te laden...</div>';

		this.$paragraphModal
			.querySelector('.modal-body')
			.insertAdjacentHTML('beforeend', _tableRow);

		this.$paragraphModal
			.querySelector('.modal-body')
			.addEventListener('scroll', function() {
				if (this.scrollTop + this.clientHeight >= this.scrollHeight) {
					self.paragraphPageIndex++;
					self.searchParagraphsByOffset(self.paragraphQuery, self.paragraphPageIndex, function(
						response
					) {
						if (
							typeof response.data !== 'undefined' &&
							response.data.length > 0
						) {
							self.updateTable(
								null,
								self.$paragraphModalList,
								self.$paragraphsList
							);
						} else {
							self.$paragraphModal.querySelector(
								'.o-table__loadmore'
							).style.display =
								'none';
						}
					});
				}
			});

		// force update of list of paragraphs
		self.updateTable(
			null,
			self.$paragraphModalList,
			self.$paragraphsList
		);
	}

	initEditor(selector) {
		window.tinyMCE.init({

			selector: '[id="' + selector + '"]',
			mode: 'none',
			hidden_input: false,
			branding: false,
			menubar: false,
			height: 250,
			images_upload_url: `${this.$hostname}/admin/uploads/store`,
			images_upload_base_path: `/`,
			relative_urls : false,
			remove_script_host : false,
			convert_urls : true,
			plugins: 'image link lists code',
			toolbar: [
				'formatselect | bold italic link | image | numlist bullist | code'
			]
		});
	}

	addParagraphLib(e) {
		for (let [key, value] of Object.entries(this.$paragraphsData)) {
			this.addNewParagraph(
				this.$holder.querySelector('.m-paragraphs__list'),
				value
			);
		}
		this.updateButtonListeners();
	}

	addChapterLib() {

		for (let [key, value] of Object.entries(this.$chaptersData)) {
			this.addNewChapter(
				this.$holder.querySelector('.m-chapters__list'),
				value
			);
		}
		this.updateButtonListeners();
	}

	updateTable(e, tableView, results) {
		if(e || false) {
			let _el = e.target;
			if (_el.getAttribute('value') === 'add-existing-paragraph') {
				this.$clickedElm = _el;
			}
		}

		let tableBody = tableView.querySelector('.o-table__body');
		tableBody.innerHTML = '';

		if (results.length > 0) {

				for (const [index, item] of results.entries()) {
					if (item.status === 'published') {
						let date = new Date(item.created_at.unix * 1000);
						let htmlTable = `<tr data-index="${index}">
							<td class="col-checkbox"><input class="label-input" value="${item.uuid}" title="${item.meta[0] ? item.meta[0].value : ''}" type="checkbox"/></td>
							<td>${item.meta[0] ? item.meta[0].value : ''}</td>
							<td class="${item.status === 'draft' ? 'concept' : 'published'}"><div class="dot"></div></td>
							<td>${date.toLocaleDateString()}</td></tr>`;

						tableBody.innerHTML += htmlTable;
					}
				}

				if (results.length <= 15) {
					if(tableView.nextElementSibling) {
						tableView.nextElementSibling.style.display = 'none';
					}
				}

		} else {
			tableView.nextElementSibling.style.display = 'none';

			let _tableRow =
				'<div class="o-table__noresults">Er zijn geen resultaten gevonden</div>';
			tableView.insertAdjacentHTML('afterend', _tableRow);
		}

		this.setupTableListener(tableView, results);
	}

	setupTableListener(tableView, results) {
		if (tableView.classList.contains('m-paragraphs-list')) {
			tableView.addEventListener('click', e => {
				if (
					e.target.type == 'checkbox' &&
					e.target.classList.contains('label-input')
				) {
					if (e.target.checked) {
						this.$paragraphsData[e.target.value] =
							results[
								e.target.parentNode.parentNode.getAttribute(
									'data-index'
								)
							];
					} else {
						delete this.$paragraphsData[e.target.value];
					}
				}
			});
		} else if (tableView.classList.contains('m-chapters-list')) {
			tableView.addEventListener('click', e => {
				if (
					e.target.type == 'checkbox' &&
					e.target.classList.contains('label-input')
				) {
					if (e.target.checked) {
						this.$chaptersData[e.target.value] =
							results[
								e.target.parentNode.parentNode.getAttribute(
									'data-index'
								)
							];
					} else {
						delete this.$chaptersData[e.target.value];
					}
				}
			});
		}
	}

	searchChapters(query, callback) {
		let self = this;
		axios.get("/admin/chapters/?rawJSON=true&filter=published&search=" + query)
			.then(function(response) {
				self.$chaptersList = response;
				callback(response);
			})
			.catch(function(error) {
				callback(error);
			});
	}

	searchParagraphs(query, callback) {
		let self = this;
		axios.get("/admin/paragraphs/?rawJSON=true&filter=published&search=" + query)
			.then(function(response) {
				self.$paragraphsList = response;
				callback(response);
			})
			.catch(function(error) {
				callback(error);
			});
	}

	searchChaptersByOffset(query, pageIndex, callback) {
		let self = this;
		let url = '/admin/chapters?rawJSON=true&filter=published&search=';

		query = query != undefined ? query : '';
		pageIndex = pageIndex != undefined ? pageIndex : 0;

		axios
			.get(
				url +
					query +
					'&page=' +
					pageIndex
			)
			.then(function(response) {
				if (response.data) {
					self.$chaptersList = self.$chaptersList.concat(
						response.data
					);
				}
				callback(response.data);
			})
			.catch(function(error) {
				callback(error);
			});
	}

	// search chapters for given query
	searchChaptersByQuery(query) {
		let self = this;

		$(this.$buttonSearchChapters).find('.fas').show();

		this.searchChapters(query, function(response) {
			$(self.$buttonSearchChapters).find('.fas').hide();
			self.updateTable(null, self.$chaptersModalList, response.data);
		});
	}

	// search paragraphs for given query
	searchParagraphsByQuery(query) {
		let self = this;

		$(this.$buttonSearchParagraphs).find('.fas').show();

		this.searchChapters(query, function(response) {
			$(self.$buttonSearchParagraphs).find('.fas').hide();
			self.updateTable(null, self.$paragraphsModalList, response.data);
		});
	}

	searchParagraphsByOffset(query, pageIndex, callback) {
		let self = this;
		axios
			.get(
				'/admin/paragraphs/?rawJSON=true&filter=published&search=' +
					query +
					'&page=' +
					pageIndex
			)
			.then(function(response) {
				if (response.data) {
					self.$paragraphsList = self.$paragraphsList.concat(
						response.data
					);
				}
				callback(response);
			})
			.catch(function(error) {
				callback(error);
			});
	}

	retrievePubishedEntities(entitiesName, callback) {
		axios
			.get('/admin/'+entitiesName+'?rawJSON=true&filter=published')
			.then(function(response) {
				callback(response.data);
			})
			.catch(function(error) {

			});
	}

	// Initialize
	init() {
		this.setup(this.$paragraphsList, this.$chaptersList);
		this.eventListeners();
		this.loadMoreParagraphs();
		this.loadMoreChapters();
	}
}

new ContentManager('.js--m-content-manager');
