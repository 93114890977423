export default function ChapterEditorTemplate(index, data, shared) {
	let html = shared
		? `
        <div class='o-card o-card--template o-card--draggable o-card--chapter bg--white' data-index='${index}'>
            <input type='hidden' name='chapters[${index}][id]' value='${
				data ? data.uuid : ''
		  }'>

			<div class='o-card__title'>
                <span class='o-label'><i class='fa fa-bookmark'></i>Hoofdstuk: &nbsp;</span><span class='o-label hidden--xs'>${
					data ? (data.meta ? data.meta[0].value : data.title) : ''
				}</span>
                <div class='o-card__actions'>
                     <a href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='true'><i class='fas fa-cog'></i></a>
					<ul class='dropdown-menu' x-placement='bottom-start' style='position: absolute; transform: translate3d(0px, 40px, 0px); top: 0px; left: 0px; will-change: transform;'>

						<li class='dropdown-item'><a href='#' data-toggle='modal' data-target='#chapterModal-${index}' style='color:#000'>Verwijderen</a></li>
						<li class='dropdown-item'><a class='js--move-up' href='#' style='color:#000'><i class='fa fa-arrow-up'></i> Omhoog</a></li>
						<li class='dropdown-item'><a class='js--move-down' href='#' style='color:#000'><i class='fa fa-arrow-down'></i> Omlaag</a></li>
					</ul>
                    <a class='o-card__toggle' href='javascript:void(0)'><i class='fas fa-minus'></i></a>
                </div>
			</div>

			<div class='modal fade' id='chapterModal-${index}' tabindex='-1' role='dialog' aria-labelledby='exampleModalLongTitle' aria-hidden='true'>
				<div class='modal-dialog' role='document'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h5 class='modal-title h3' id='Item verwijderen'>Item verwijderen</h5>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close'>
								<i class='fa fa-times'></i>
							</button>
						</div>
						<div class='modal-body'>
							<p>Weet je zeker dat je <i>'${
								data
									? data.meta
										? data.meta[0].value
										: data.title
									: 'dit item'
							}'</i> wilt verwijderen?</p>
						</div>
						<div class='modal-footer'>
							<button type='button' class='o-btn o-btn--secondary' data-dismiss='modal'>Annuleren</button>
							<button  type='button' class='o-btn o-btn--primary js--delete-confirm' data-dismiss='modal'>Verwijderen</button>
						</div>
					</div>
				</div>
			</div>

            <div class='o-card__content'>

                <div class='form-group'>
					<label for='chapters[${index}][title]'>Titel</label>

					<div class='o-card--template__title'>${
						data
							? data.meta
								? data.meta[0].value
								: data.title
							: ''
					}</div>
                    <input type='hidden' name='chapters[${index}][title]' id='chapters[${index}][title]' rows='4' cols='50' value='${
				data ? (data.meta ? data.meta[0].value : data.title) : ''
		  }'>

				</div>
				  <div class='form-group'>
				  <label for='chapters[${index}][content]'>Content</label>
					<div class='o-card--template__content'>
						${data ? (data.meta ? data.meta[1].value : data.text) : ''}
					</div>
				</div>
			${data.contents
				.map(
					(paragraph, paragraph_index) => `
				  <div class='o-card o-card o-card--template o-card--draggable o-card--chapter bg--white' data-index='${paragraph_index}'>

					<div class='o-card__title'>
		  				<span class='o-label'><i class='fa fa-bookmark'></i>Paragraaf: &nbsp; ${
							paragraph
								? paragraph.meta
									? paragraph.meta[0]
										? paragraph.meta[0].value
										: ''
									: paragraph.title
								: ''
						}</span>
						<div class='o-card__actions'>
							<a class='o-card__toggle' href='javascript:void(0)'><i class='fas fa-minus'></i></a>
						</div>
					</div>

					<div class='o-card__content'>

						<div class='form-group'>
							<label for='chapters[${index}][paragraphs][${paragraph_index}][title]'>Titel</label>
							<div class='o-card--template__title'>${
								paragraph
									? paragraph.meta
										? paragraph.meta[0]
											? paragraph.meta[0].value
											: ''
										: paragraph.title
									: ''
							}</div>
							<input type='hidden' name='chapters[${index}][paragraphs][${paragraph_index}][title]' id='chapters[${index}][paragraphs][${paragraph_index}][title]' rows='4' cols='50' value='${String(
						paragraph
							? paragraph.meta
								? paragraph.meta[0]
									? paragraph.meta[0].value
									: ''
								: paragraph.title
							: ''
					)}'>

						</div>
						<div class='form-group'>
							<label>Content</label>
							<div class='o-card--template__content'>
								<input type='hidden' name='chapters[${index}][paragraphs][${paragraph_index}][content]' value='${String(
						paragraph
							? paragraph.meta
								? paragraph.meta[1].value
									? paragraph.meta[1].value
									: ''
								: paragraph.content
							: ''
					)}'>
	${String(
		paragraph
			? paragraph.meta
				? paragraph.meta[1].value
					? paragraph.meta[1].value
					: ''
				: paragraph.content
			: ''
	)}
							</div>
						</div>
					</div>
				</div>
				`
				)
				.join('')}
			</div>
        </div>`
		: `
	<div class='ui-sortable o-card  o-card--draggable o-card--chapter bg--white ' data-index='${index}'>
    ${
		data
			? `<input type='hidden' name='chapters[${index}][id]' value='${
					data.uuid
			  }'>`
			: ''
	}

        <div class='o-card__title'>
            <span class='o-label'><i class='fa fa-bookmark'></i>Hoofdstuk: &nbsp; ${String(
				data ? (data.meta ? data.meta[0].value : data.title) : ''
			)}</span>

            <div class='o-card__actions'>
                <a href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='true'><i class='fas fa-cog'></i></a>
                <ul class='dropdown-menu' x-placement='bottom-start' style='position: absolute; transform: translate3d(0px, 40px, 0px); top: 0px; left: 0px; will-change: transform;'>

                    <li class='dropdown-item'><a href='#' data-toggle='modal' data-target='#chapterModal-${index}' style='color:#000'>Verwijderen</a></li>
                    <li class='dropdown-item'><a class='js--move-up' href='#' style='color:#000'><i class='fa fa-arrow-up'></i> Omhoog</a></li>
                    <li class='dropdown-item'><a class='js--move-down' href='#' style='color:#000'><i class='fa fa-arrow-down'></i> Omlaag</a></li>
                </ul>
               <a class='o-card__toggle' href='javascript:void(0)'><i class='fas fa-minus'></i></a>
            </div>
        </div>

        <div class='modal fade' id='chapterModal-${index}' tabindex='-1' role='dialog' aria-labelledby='exampleModalLongTitle' aria-hidden='true'>
            <div class='modal-dialog' role='document'>
                <div class='modal-content'>
                    <div class='modal-header'>
                        <h5 class='modal-title h3' id='Item verwijderen'>Item verwijderen</h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                            <i class='fa fa-times'></i>
                        </button>
                    </div>
                    <div class='modal-body'>
                        <p>Weet je zeker dat je dit item wilt verwijderen?</p>
                    </div>
                    <div class='modal-footer'>
                        <button type='button' class='o-btn o-btn--secondary' data-dismiss='modal'>Annuleren</button>
                        <button  type='button' class='o-btn o-btn--primary js--delete-confirm' data-dismiss='modal'>Verwijderen</button>
                    </div>
                </div>
            </div>
        </div>


        <div class='o-card__content'>
            <div class='form-group'>
                <label for='chapters[${index}][title]'>Titel</label>

                <input type='text' name='chapters[${index}][title]' id='chapters[${index}][title]' value='${
				data ? (data.meta ? data.meta[0].value : data.title) : ''
		  }' placeholder='Titel van dit hoofdstuk' class='o-inputfield'>
			</div>
			<div class='form-group'>
                <label for='chapters[${index}][content]'>Introtekst</label>
				 <div class='o-editor'>
                    <div class='o-editor__form'>
                        <textarea id='chapter-editor-${index}' name='chapters[${index}][content]' data-sibling='chapters[${index}][content]' class='o-editor__textarea' rows='4' cols='50' aria-hidden='true'>${String(
				data
					? data.meta
						? data.meta[1].value
							? data.meta[1].value
							: ''
						: data.content
					: ''
		  )}</textarea>
                    </div>
                </div>
            </div>
            <div class='m-content-manager__list m-paragraphs__list'>


			</div>
			<div class='form-group'>
				<button class='o-btn o-btn--outline o-btn--icon-r dropdown-toggle' type='button' id='dropdownMenuAddParagraph' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
					Toevoegen aan hoofdstuk <i class='fa fa-chevron-down'></i>
				</button>
				<ul class='dropdown-menu' aria-labelledby='dropdownMenuAddParagraph' x-placement='top-start' style='position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -75px, 0px);'>
					<li class='dropdown-item'><a href='javascript:void(0)' value='add-new-paragraph'>Nieuwe paragraaf</a></li>
					<li class='dropdown-item'><a href='javascript:void(0)' value='add-existing-paragraph' data-toggle='modal' data-target='#m-modal-paragraphs'>Paragraaf uit bibliotheek</a></li>
				</ul>
			</div>
        </div>
	</div>`;

	return html;
}
