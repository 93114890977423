class StatusSwitcher {
	constructor(holder) {
		this.$holder = document.querySelector(holder);

		// Binding THIS
		this.setup = this.setup.bind(this);
		this.toggleTarget = this.toggleTarget.bind(this);

		// If holder exists
		if (this.$holder) {
			this.init();
		}
	}

	setup() {
		this.$switcher = this.$holder.querySelector('.m-status-switcher');
		if (this.$switcher) {
			this.$switcher.addEventListener('click', this.toggleTarget);
		}
	}

	toggleTarget(e) {
		let targetShow = e.target.getAttribute('switch-show');
		let targetHide = e.target.getAttribute('switch-hide');
		let targetHideSelf = e.target.getAttribute('switch-hide-self');

		if (targetHide) {
			document.querySelector(targetHide).style.display = 'none';
		}
		if (targetHideSelf) {
			e.target.style.display = 'none';
		}
		if (targetShow) {
			document.querySelector(targetShow).style.display = 'block';
		}
	}

	// Initialize
	init() {
		this.setup();
	}
}

new StatusSwitcher('.js--m-status-switcher');
