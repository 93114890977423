export {
	default as ContentManager
} from './m-content-manager/js/m-content-manager';
export { default as Labels } from './m-labels/js/m-labels';
export { default as Sidebar } from './m-sidebar/js/m-sidebar';
export {
	default as StatusSwitcher
} from './m-status-switcher/js/m-status-switcher';
// export { default as ParagraphsModal } from './m-paragraphs-modal/js/m-paragraphs-modal';
// export { default as ChaptersModal } from './m-chapters-modal/js/m-chapters-modal';
export { default as StatusMenu } from './m-status-menu/js/m-status-menu';
export { default as UserAccess } from './m-user-access/js/m-user-access';
