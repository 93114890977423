export default class StatusMenu {
	constructor(holder) {
		this.$holder = document.querySelector(holder);

		// Binding THIS
		this.setup = this.setup.bind(this);

		// If holder exists
		if (this.$holder) {
			this.init();
		}
	}

	setup() {
		this.$wrapper = document.querySelector('.l-wrapper');
		this.$sidebar = this.$wrapper.querySelector('.l-content__right');
		this.$content = this.$wrapper.querySelector('.l-content__left');

		this.$content.style.minHeight = this.$sidebar.clientHeight + 'px';
	}

	makeSticky() {
		if(this.$holder != null && this.$holder !== undefined) {
            this.$holderWidth = this.$holder.clientWidth;

            if (this.$holder) {
                if (
                    window.innerWidth >= 991 &&
                    window.innerHeight < window.outerHeight
                ) {
                    if (this.$wrapper.scrollTop > 160) {
                        this.$sidebar.style.paddingTop = 250 + 'px';
                        this.$holder.classList.add('is--sticky');
                        this.$holder.style.width = `${this.$holderWidth}px`;
                        // $sidebar.style.paddingTop = wrapper.scrollTop + 160 + 'px';
                    } else {
                        this.$holder.classList.remove('is--sticky');
                        this.$holder.style.width = '';
                        this.$sidebar.style.paddingTop = '';
                    }
                } else {
                    this.$holder.classList.remove('is--sticky');
                    this.$holder.style.width = '';
                    this.$sidebar.style.paddingTop = '';
                }
            }
        }
	}

	// Initialize
	init() {
		this.setup();
	}
}
