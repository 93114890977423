/**
 * @return {string}
 */
export default function ParagraphEditorTemplate(index, data, shared) {
	let html = shared
		? `

        <div class='o-card o-card--template o-card--draggable o-card--paragraph bg--white'>
            <input type='hidden' name='paragraphs[${index}][id]' value='${
				data ? data.uuid : ''
		  }'>

            <div class='o-card__title'>
                <span class='o-label'><i class='fa fa-paragraph'></i>Paragraaf: &nbsp;</span><span class='o-label hidden--xs'>${
					data ? (data.meta ? data.meta[0].value : data.title) : ''
				}</span>

                <div class='o-card__actions'>
                   	<a href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='true'><i class='fas fa-cog'></i></a>
					<ul class='dropdown-menu' x-placement='bottom-start' style='position: absolute; transform: translate3d(0px, 40px, 0px); top: 0px; left: 0px; will-change: transform;'>
						<li class='dropdown-item'><a href='#' data-toggle='modal' data-target='#paragraphModal-${index}' style='color:#000'>Verwijderen</a></li>
						<li class='dropdown-item'><a class='js--move-up' href='#' style='color:#000'><i class='fa fa-arrow-up'></i> Omhoog</a></li>
						<li class='dropdown-item'><a class='js--move-down' href='#' style='color:#000'><i class='fa fa-arrow-down'></i> Omlaag</a></li>
					</ul>
                    <a class='o-card__toggle' href='javascript:void(0)'><i class='fas fa-minus'></i></a>
				</div>

				<div class='modal fade' id='paragraphModal-${index}' tabindex='-1' role='dialog' aria-labelledby='exampleModalLongTitle' aria-hidden='true'>
					<div class='modal-dialog' role='document'>
						<div class='modal-content'>
							<div class='modal-header'>
								<h5 class='modal-title h3' id='Item verwijderen'>Item verwijderen</h5>
								<button type='button' class='close' data-dismiss='modal' aria-label='Close'>
									<i class='fa fa-times'></i>
								</button>
							</div>
							<div class='modal-body'>
								<p>Weet je zeker dat je <i>'${
									data
										? data.meta
											? data.meta[0].value
											: data.title
										: 'dit item'
								}'</i> wilt verwijderen?</p>
							</div>
							<div class='modal-footer'>
								<button type='button' class='o-btn o-btn--secondary' data-dismiss='modal'>Annuleren</button>
								<button  type='button' class='o-btn o-btn--primary js--delete-confirm' data-dismiss='modal'>Verwijderen</button>
							</div>
						</div>
					</div>
				</div>

            </div>
            <div class='o-card__content'>

                <div class='form-group'>
					<label for='paragraphs[{{ $paragraphIndex  }}][title]'>Titel</label>
					<div class='o-card--template__title'>${
						data
							? data.meta
								? data.meta[0].value
								: data.title
							: ''
					}</div>
                    <input type='hidden' name='paragraphs[${index}][title]' id='paragraphs[${index}][title]' rows='4' cols='50' value='${
				data ? (data.meta ? data.meta[0].value : data.title) : ''
		  }'>
                </div>

                <div class='form-group'>
                    <div class='o-editor'>
                        <div class='o-editor__form' method='post'>
								<label for=''>Content</label>
								<div class='o-card--template__content'>
                               ${
									data
										? data.meta
											? data.meta[1].value
											: data.content
										: ''
								}
								</div>
                                <input type='hidden' id='editor-${index}' name='paragraphs[${index}][content]' data-sibling='${
				data ? data.uuid : ''
		  }' class='' rows='4' cols='50' value='${
				data ? (data.meta ? data.meta[1].value : data.content) : ''
		  }'>
                        </div>
                    </div>
                </div>

            </div>
        </div>`
		: `<div class='ui-sortable o-card  o-card--draggable o-card--paragraph bg--white '>${
				data
					? `<input type='hidden' name='paragraphs[${index}][id]' value='${
							data.uuid
					  }'>`
					: ''
		  }
        <div class='o-card__title'>
            <span class='o-label'><i class='fa fa-paragraph'></i>Paragraaf: &nbsp; ${
				data ? (data.meta ? data.meta[0].value : data.title) : ''
			}</span><span class='o-label hidden--xs'></span>

            <div class='o-card__actions'>
                <a href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='true'><i class='fas fa-cog'></i></a>
                <ul class='dropdown-menu' x-placement='bottom-start' style='position: absolute; transform: translate3d(0px, 40px, 0px); top: 0px; left: 0px; will-change: transform;'>

                    <li class='dropdown-item'><a href='#' data-toggle='modal' data-target='#paragraphModal-${index}'>Verwijderen</a></li>
                    <li class='dropdown-item'><a class='js--move-up' href='#'><i class='fa fa-arrow-up'></i> Omhoog</a></li>
                    <li class='dropdown-item'><a class='js--move-down' href='#'><i class='fa fa-arrow-down'></i> Omlaag</a></li>
                </ul>
                <a class='o-card__toggle' href='javascript:void(0)'><i class='fas fa-minus'></i></a>
            </div>
        </div>

        <div class='modal fade' id='paragraphModal-${index}' tabindex='-1' role='dialog' aria-labelledby='exampleModalLongTitle' aria-hidden='true'>
            <div class='modal-dialog' role='document'>
                <div class='modal-content'>
                    <div class='modal-header'>
                        <h5 class='modal-title h3' id='Item verwijderen'>Item verwijderen</h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                            <i class='fa fa-times'></i>
                        </button>
                    </div>
                    <div class='modal-body'>
                        <p>Weet je zeker dat je dit item wilt verwijderen?</p>
                    </div>
                    <div class='modal-footer'>
                        <button type='button' class='o-btn o-btn--secondary' data-dismiss='modal'>Annuleren</button>
                        <button  type='button' class='o-btn o-btn--primary js--delete-confirm' data-dismiss='modal'>Verwijderen</button>
                    </div>
                </div>
            </div>
        </div>


        <div class='o-card__content'>
            <div class='form-group'>
                <label for='paragraphs[${index}][title]'>Titel</label>
                <input type='text' name='paragraphs[${index}][title]' id='paragraphs[${index}][title]' value='${
				data ? (data.meta ? data.meta[0].value : data.title) : ''
		  }' placeholder='Titel van deze paragraaf' class='o-inputfield'>
            </div>
            <div class='form-group'>
                <div class='o-editor'>
                    <div class='o-editor__form' method='post'>
                        <textarea id='paragraph-editor-${index}' name='paragraphs[${index}][content]' data-sibling='paragraphs[${index}][content]' class='o-editor__textarea' rows='4' cols='50' aria-hidden='true'>${
				data ? (data.meta ? data.meta[1].value : data.content) : ''
		  }</textarea>
                    </div>
                </div>
            </div>
        </div>

    </div>`;

	return html;
}
