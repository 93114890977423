/*eslint-disable */
import axios from 'axios';
export default class Labels {
	constructor(holder) {
		this.$holder = document.querySelector(holder);

		// Binding THIS
		this.setup = this.setup.bind(this);
		this.retrieveLabels = this.retrieveLabels.bind(this);
		this.eventListeners = this.eventListeners.bind(this);
		this.updateTable = this.updateTable.bind(this);
		this.setupTableListener = this.setupTableListener.bind(this);
		this.addingLabels = this.addingLabels.bind(this);
		this.loadMore = this.loadMore.bind(this);

		this.pageIndex = 0;
		this.query = '';

		// If holder exists
		if (this.$holder) {
			this.retrieveLabels(() => {
				this.init();
			});
		}
	}

	setup() {
		this.$inputSearchLabels = this.$holder.querySelectorAll(
			'.m-labels-modal .o-inputfield__search'
		);
		this.$addLabelsBtn = this.$holder.querySelector(
			'[data-target="#m-labels-modal"]'
		);

		this.$dataList = {};
		this.$saveLabels = this.$holder.querySelector('.o-btn--save');
		this.$tableView = this.$holder.querySelector(
			'.m-labels__list .o-table__body'
		);
		this.$labelsAddedList = this.$holder.querySelector('.m-labels__added');
		this.$removeLabels = this.$labelsAddedList.querySelectorAll(
			'.m-labels--btn__close'
		);

		this.updateEventsListAdded();
	}

	loadMore() {
		let self = this;
		// let _pageIndex = 0;
		let _tableRow =
			'<div class="o-table__loadmore">Scroll omlaag om meer te laden...</div>';

		this.$holder
			.querySelector('.modal-body')
			.insertAdjacentHTML('beforeend', _tableRow);

		this.$holder
			.querySelector('.modal-body')
			.addEventListener('scroll', function() {
				if (this.scrollTop + this.clientHeight >= this.scrollHeight) {

					console.log(self.query, self.pageIndex);
					self.pageIndex++;
					self.searchLabelsByOffset(self.query, self.pageIndex, function(
						response
					) {
						if (
							typeof response.data !== 'undefined' &&
							response.data.length > 0
						) {
							self.updateTable(self.$tableView, self.labels);


						} else {
							self.$holder.querySelector(
								'.o-table__loadmore'
							).style.display =
								'none';

						}
					});
				}
			});
	}

	eventListeners() {
		this.$saveLabels.addEventListener('click', this.addingLabels);
		this.prepareSearchInputLabels(this.$inputSearchLabels);

		this.$addLabelsBtn.addEventListener('click', () => {
			document.onkeypress = e => {
				var key = e.charCode || e.keyCode || 0;
				if (key === 13) {
					e.preventDefault();
				}
			};
		});
	}

	prepareSearchInputLabels(ell) {
		let self = this;

		for (let [key, inputField] of Object.entries(ell)) {
			inputField.addEventListener('input', event => {
				let query = event.target.value;


				this.query = query;
				this.pageIndex = 0;
				self.searchLabels(query, function() {
					self.updateTable(self.$tableView, self.labels);
				});
			});
		}
	}

	searchLabels(query, callback) {
		let self = this;
		axios
			.get('/admin/tags/?rawJSON=true&search=' + query)
			.then(function(response) {
				self.labels = response.data;
				callback(response);
			})
			.catch(function(error) {
				callback(error);
			});
	}

	searchLabelsByOffset(query, pageIndex, callback) {
		let self = this;

		// console.log(query, pageIndex);
		axios
			.get(
				'/admin/tags/?rawJSON=true&search=' +
					query +
					'&page=' +
					pageIndex
			)
			.then(function(response) {
				if (response.data) {
					self.labels = self.labels.concat(response.data);
				}
				callback(response);
			})
			.catch(function(error) {
				callback(error);
			});
	}

	updateEventsListAdded() {
		this.$removeLabels = this.$labelsAddedList.querySelectorAll(
			'.m-labels--btn__close'
		);

		// Reset checkboxes
		let checkboxes = this.$tableView.querySelectorAll('input');
		for (let i = 0; i < checkboxes.length; i++) {
			checkboxes[i].checked = false;
		}

		for (let i = 0; i < this.$removeLabels.length; i++) {
			if (
				this.$removeLabels[i] != undefined &&
				this.$removeLabels[i] != null
			) {
				this.$removeLabels[i].removeEventListener(
					'click',
					this.eventHandler
				);

				this.$removeLabels[i].addEventListener('click', e => {
					let labelLi = e.target.closest('li');
					labelLi.parentNode.removeChild(labelLi);
				});
			}
		}
	}

	eventHandler() {}

	updateTable(tableView, labels) {
		tableView.innerHTML = '';
		labels.map(label => {
			let date = new Date(label.created_at.unix * 1000);
			let htmlLabel = `<tr><td class="col-checkbox"><input class="label-input" value="${
				label.uuid
			}" title="${label.title}" type="checkbox"/></td><td>${
				label.title
			}</td><td>${date.toLocaleDateString()}</td></tr>`;

			tableView.innerHTML += htmlLabel;
		});

		if (labels.length <= 15) {
			tableView.parentNode.nextElementSibling.style.display = 'none';
		}

		this.setupTableListener(tableView);
	}

	addingLabels() {
		let tableView = this.$holder.querySelector('.o-card__content ul');

		for (let [key, value] of Object.entries(this.$dataList)) {
			let existing = this.$holder.querySelectorAll(
				'.o-card__content ul [value="' + key + '"]'
			);

			if (existing.length == 0) {
				let button =
					'<button type="button" class="m-labels--btn__close"><i class="fas fa-times"></i></button>';

				let htmlLabel = `<li><label for="tag-${key}">${button}${value}</label><input name="tags[]" type="hidden" value="${key}"></li>`;
				tableView.innerHTML += htmlLabel;
			}
		}

		this.$dataList = {};
		this.updateEventsListAdded();
	}

	setupTableListener(tableView) {
		tableView.addEventListener('click', e => {
			if (
				e.target.type == 'checkbox' &&
				e.target.classList.contains('label-input')
			) {
				if (e.target.checked) {
					this.$dataList[e.target.value] = e.target.getAttribute(
						'title'
					);
				} else {
					delete this.$dataList[e.target.value];
				}
			}
		});
	}

	retrieveLabels(callback) {
		let self = this;
		axios
			.get('/admin/tags?rawJSON=true')
			.then(function(response) {
				// handle success

				self.labels = response.data;
				callback();
			})
			.catch(function(error) {
				// handle error
				// eslint-disable-next-line no-console
				console.log(error);
			});
	}

	// Initialize
	init() {
		this.setup();
		this.loadMore();
		this.eventListeners();
		this.updateTable(this.$tableView, this.labels);
	}
}

new Labels('.js--m-labels');
