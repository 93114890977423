import './index.scss';
import ClipboardJS from 'clipboard';
// import $ from 'jquery'; // eslint-disable-line no-unused-vars

import { Sidebar, StatusMenu } from './components/modules/index';
import { Tabs } from './components/objects/index';

/**
 * --------------------------------------------------------------------------
 * Define instances
 * --------------------------------------------------------------------------
 */

new ClipboardJS('.o-copy-to-clipboard__btn');

const sidebar = new Sidebar('.js--m-sidebar');
const statusMenu = new StatusMenu('.js--m-status-menu');

/**
 * --------------------------------------------------------------------------
 * jQuery Detection
 * --------------------------------------------------------------------------
 */

// eslint-disable-next-line no-shadow
// ($) => {
// 	if (typeof $ === 'undefined') {
// 		throw new TypeError(
// 			'The frontend requires jQuery. Jquery must be included before the framework javascript.'
// 		);
// 	}
// };

/**
 * --------------------------------------------------------------------------
 * Resize Listeners
 * --------------------------------------------------------------------------
 */

window.onresize = function() {
	sidebar.reset();
	statusMenu.makeSticky();
};

/**
 * --------------------------------------------------------------------------
 * Scroll Listeners
 * --------------------------------------------------------------------------
 */

const isScrolling = (event) => {
	statusMenu.makeSticky(event);
};
const wrapper = document.querySelector('.l-wrapper');
wrapper.addEventListener('scroll', isScrolling);
