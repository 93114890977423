/*eslint-disable */
export default class UserAccess {
	constructor(holder) {
		this.$holder = document.querySelector(holder);

		// Binding THIS
		this.setup = this.setup.bind(this);
		this.toggleLabels = this.toggleLabels.bind(this);
		this.updateAccess = this.updateAccess.bind(this);
		this.eventListeners = this.eventListeners.bind(this);

		// If holder exists
		if (this.$holder) {
			this.init();
		}
	}
	setup() {

		this.$rolesSelect = this.$holder.querySelector('.m-user-access__roles');
		this.$typesHolder = this.$holder.querySelector('.m-user-access__types');
		this.$allContent = this.$typesHolder.querySelector(
			'.m-user-access__types--all'
		);
		this.$specificContent = this.$typesHolder.querySelector(
			'.m-user-access__types--specific'
		);
		this.$labelsHolder = this.$holder.querySelector(
			'.m-user-access__labels'
		);
		this.$userRole = this.$rolesSelect[
			this.$rolesSelect.selectedIndex
		].value;

		// Check access and show/hide content selector
		if (this.$allContent.querySelector('input').checked) {
			this.$labelsHolder.style.display = 'none';
		} else {
			this.$specificContent.style.display = 'block';

		}
	}

	eventListeners() {
		this.$rolesSelect.addEventListener('change', this.updateAccess);
		this.$specificContent.addEventListener('change', this.toggleLabels);
		this.$allContent.addEventListener('change', this.toggleLabels);
	}

	updateAccess(event) {

		if (event) {
			this.$userRole = event.target[event.target.selectedIndex].value;

			if (this.$userRole === '4') {
				this.$allContent.querySelector('input[type="radio"]').checked = true;
				this.$specificContent.style.display = 'none';
				this.$labelsHolder.style.display = 'none';
			} else if (this.$userRole === '5') {
				this.$allContent.querySelector(
					'input[type="radio"]'
				).checked = false;
				this.$specificContent.style.display = 'block';
			}
		}


	}

	toggleLabels(event) {
		const _el = event.target;

		if (_el.id === 'restricted' && _el.checked) {
			this.$labelsHolder.style.display = 'block';
		} else {
			this.$labelsHolder.style.display = 'none';
		}
	}

	// Initialize
	init() {
		this.setup();
		this.updateAccess();
		this.eventListeners();
	}
}

new UserAccess('.js--m-user-access');
