export default class Tabs {
	constructor(holder) {
		this.$holder = document.querySelector(holder);

		// Binding THIS
		this.setup = this.setup.bind(this);
		this.toggleTabs = this.toggleTabs.bind(this);
		this.eventListeners = this.eventListeners.bind(this);

		// If holder exists
		if (this.$holder) {
			this.init();
		}
	}

	setup() {
		this.$tab = this.$holder.querySelectorAll('.o-tabs__item');
		this.$tabPane = this.$holder.querySelectorAll('.o-tabs__pane');
	}

	eventListeners() {
		for (let i = 0; i < this.$tab.length; i++) {
			this.$tab[i].addEventListener('click', this.toggleTabs);
		}
	}

	toggleTabs(event) {
		for (let i = 0; i < this.$tabPane.length; i++) {
			this.$tab[i].classList.remove('is--active');
			this.$tabPane[i].classList.remove('is--active');
		}

		event.target.classList.add('is--active');

		document
			.getElementById(event.target.getAttribute('data-id'))
			.classList.add('is--active');
	}

	// Initialize
	init() {
		this.setup();
		this.eventListeners();
	}
}

new Tabs('.js--o-tabs');
